import {LeftMenuModule} from "ditmer-embla"

$(document).ready( () => {
    new LeftMenuModule({
        toggleMenuSelector: ".left-menu-header-toggle",
        persistState: true,
        persistStateForDays: 1,
    })
    
    $(".sub-menu-header").off().on("click", function(){
        let item = $(this);
        let iconOuterDiv = item.find(".sub-menu-toggle-icon-submenu");
        let iconInnerDiv = iconOuterDiv.find(".embla-icon");
        
        //Hvis submenuen er foldet ud
        if(item.siblings(".in").length > 0){
            $(iconInnerDiv).html(
                "<svg class=\"embla-icon\" aria-hidden=\"true\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
                "<use xlink:href=\"/wwwroot/dist/icons/sprite.symbol.svg#arrow-right\"></use>\n" +
                "</svg>"
            )
        }
        else {
            item.addClass("expanded")
            $(iconInnerDiv).html(
            "<svg class=\"embla-icon\" aria-hidden=\"true\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
            "<use xlink:href=\"/wwwroot/dist/icons/sprite.symbol.svg#arrow-down\"></use>\n" +
            "</svg>"
            )
        }
    })
})
